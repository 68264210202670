/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { DefaultPaginationRequestData } from "@components/Table/TableBuilder";
import { CustomData } from "@context/CustomFieldProvider";
import { fnPaginationParams, PaginatorParams } from "@hook/usePaginationParams";
import { FileUrlType } from "@services/FileTypes";
import { DateTime } from "luxon";

import { DeafultResponseError } from "./ApiAdapter";
import { Basedata, BasedataType, Manufacturer } from "./ApparatusBasedataAdapter";
import { ApparatusCommentModel } from "./ApparatusCommentAdapter";
import { ApparatusLocationBaseModel } from "./ApparatusLocationAdapter";
import { CalibrationPlaces } from "./CalibrationPlacesAdapter";
import { CostcenterModel } from "./CostcenterAdapter";
import { InspectionModel } from "./InspectionAdapter";
import { TestplanModel } from "./TestplanAdapter";

export type CheckIntervalUnit = "days" | "weeks" | "months" | "years";

export enum ExportTypes {
    EXCEL = "excel",
    PDF = "pdf",
}

export enum ApparatusStatus {
    OPERATIONAL = "operational",
    NOT_AVAILABLE = "not_available",
    OUT_OF_HOUSE = "out_of_house",
    UNDETECTABLE = "undetectable",
    DECOMMISSIONED = "decommissioned",
    LOCKED = "locked",
    DEFECT = "defect",
    SCRAPPED = "scrapped",
    NOT_OPERATIONAL = "not_operational",
    ACTION_REQUIRED = "action_required",
}

export interface BaseData extends Model {
    uuid: string;
    manufactor_id?: number;
    model: string;
    name: string;
    description: string;
    apparatus_basedata_types?: BasedataType[];
    manufacturer?: Manufacturer;
}

export type IntervalUnitListType = {
    [Property in CheckIntervalUnit]: CheckIntervalUnit;
};

export const intervalUnitList: IntervalUnitListType = {
    days: "days",
    weeks: "weeks",
    months: "months",
    years: "years",
};

export interface ApparatusCheckintervalModel {
    id: number;
    check_interval: number;
    interval_unit: CheckIntervalUnit;
}

export interface FileUploads {
    id: number;
    original_name: string;
    file_type: string;
    url: string;
    created: string;
    uploadtype_id?: number;
    file_size?: number;
    upload_info?: string;
    uploadowner?: {
        upload_id: string;
        user: {
            name: string;
            surname: string;
            uuid: string;
        };
    };
}

export interface ApparatusesLogsUser extends Model {
    creator_name: string;
    model: string;
    action: string;
    user_img?: string | null;
    api: boolean;
}

export interface ApparatusModel extends Model {
    uuid: string;
    draft?: number;
    costcenter_id: number;
    apparatus_basedata_id: number;
    apparatus_basedata: Basedata;
    costcenter: CostcenterModel;
    status: ApparatusStatus;
    inspections?: InspectionModel[];
    testplans?: TestplanModel[];
    delivered_at: DateTime | null;
    is_customer_calibrated: boolean;
    identno: string;
    serial_number: string;
    checkup: boolean;
    archived: DateTime | null;
    apparatuses_checkinterval_id?: number;
    apparatuses_checkinterval?: ApparatusCheckintervalModel;
    check_interval: string | null;
    apparatuses_locations_bases_id?: number;
    last_checked_at: DateTime | null;
    next_check_at: DateTime | null;
    repair_times: number;
    uploads?: FileUploads[];
    apparatuses_comments?: ApparatusCommentModel[];
    log_update: boolean;
    last_modified_user?: ApparatusesLogsUser;
    apparatuses_locations_base?: ApparatusLocationBaseModel | null;
    calibration_places?: CalibrationPlaces;
    company_id?: number;
}

export interface ModelSingleSearch {
    headline: string;
    name: string;
    key: string;
    link: string | number;
    extraValues: string[];
}

export interface ModelSearchResponse {
    title: string;
    dataColumns: ModelSingleSearch[];
}

export interface ApparatusIndexModel extends Model {
    uuid: string;
    company_id: number;
    apparatus_basedata_id: number;
    apparatuses_checkinterval_id?: number;
    apparatuses_locations_bases_id?: number;
    user_id: number;
    costcenter_id: number;
    status: ApparatusStatus;
    is_customer_calibrated: boolean;
    identno: string;
    serial_number: string;
    checkup: boolean;
    archived: DateTime | null;
    manufactor_id: number;
    model: string;
    costcenter_name: string;
    manufacturer_name: string;
    apparatus_basedata: Basedata;
    next_check_at: DateTime | null;
    latest_checked: DateTime | null;
    location_info?: string;
}

export interface ApparatusAddModel extends Model {
    id?: number;
    draft?: number;
    costcenter_id: number;
    delivered_at?: DateTime;
    apparatus_basedata_id: number;
    is_customer_calibrated: boolean;
    identno: string;
    serial_number: string;
    checkup: boolean;
    checkinterval_id?: number;
    checkinterval_isNew?: boolean;
    checkinterval_number?: number;
    checkinterval_unit?: CheckIntervalUnit;
    apparatuses_locations_bases_id?: number;
    calibrationPlace_id?: number;
    description?: string;
    customFields?: CustomData;
}

export interface ApparatusAddReturn extends Model {
    success: boolean;
    apparatus?: ApparatusModel;
    errors?: DeafultResponseError;
}

export interface NextApparatusIdentNo {
    success: boolean;
    nextId: string;
}

export interface FileExportsWithProgress {
    success: boolean;
    job_id: number | null;
    reference: string | null;
    progress: number | null;
    fileId?: string;
    name?: string;
    url?: string;
    types?: ExportTypes[];
}

export interface ApparatusName {
    model: string;
    manufacturer_name: string;
    name: string;
    identno: string;
    costcenter_name: string;
}

export interface ApparatusModelPaginator extends Paginator {
    apparatuses: ApparatusModel[];
}

export default class ApparatusAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    ApparatusModel,
    ApparatusModelPaginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("apparatuses", "apparatus", "apparatuses", adapter);
    }

    public async addApparatus(addData: ApparatusAddModel): Promise<ApparatusAddReturn> {
        const { data } = await this._post<ApparatusAddReturn>(`add`, addData);

        return data;
    }

    public async getDraftApparatus(): Promise<ApparatusModel> {
        const { data } = await this._get<{ apparatus: ApparatusModel }>(`getDraft`);

        return data.apparatus;
    }

    public async getApparatusesName(id: string): Promise<ApparatusName> {
        const { data } = await this._get<{ apparatuses: ApparatusName }>(
            `getApparatusesName/${id}`
        );

        return data.apparatuses;
    }

    public async editApparatus(
        id: number,
        editData: ApparatusAddModel
    ): Promise<ApparatusAddReturn> {
        const { data } = await this._post<ApparatusAddReturn>(`edit/${id}`, editData);

        return data;
    }

    public async index(
        paginator?: PaginatorParams
    ): Promise<DefaultPaginationRequestData<ApparatusIndexModel>> {
        const { params } = fnPaginationParams(paginator);

        const { data } = await this._get<DefaultPaginationRequestData<ApparatusIndexModel>>(
            `${params}`
        );

        return data;
    }

    public async generateListExport(
        ids: number[],
        selectedFields: string[],
        selectedHeaders: string[],
        type: ExportTypes = ExportTypes.EXCEL,
        paginator?: PaginatorParams
    ): Promise<FileExportsWithProgress> {
        const { params } = fnPaginationParams(paginator);

        const exportParams = {
            ids,
            selectedFields,
            selectedHeaders,
            type,
        };

        const { data } = await this._post<FileExportsWithProgress>(`export${params}`, exportParams);

        return data;
    }

    public async generateSingleExport(id: number): Promise<FileExportsWithProgress> {
        const { data } = await this._get<FileExportsWithProgress>(`export/${id}`);

        return data;
    }

    public async getExportWithProgress(id: number): Promise<FileExportsWithProgress> {
        const { data } = await this._get<FileExportsWithProgress>(`export/progress/${id}`);

        return data;
    }

    public async getIntervalIndex(): Promise<ApparatusCheckintervalModel[]> {
        const { data } = await this._get<{ interval: ApparatusCheckintervalModel[] }>(
            `intervalIndex`
        );

        return data.interval;
    }

    public async getNextIdentNo(): Promise<NextApparatusIdentNo> {
        const { data } = await this._get<NextApparatusIdentNo>(`getNextIdentNo`);

        return data;
    }

    public async getIndexList(search = ""): Promise<ApparatusModel[]> {
        const { data } = await this._get<{ apparatuses: ApparatusModel[] }>(
            `getIndex?search=${search}`
        );

        return data.apparatuses;
    }

    public async getSearch(search = ""): Promise<ModelSearchResponse> {
        const { data } = await this._get<ModelSearchResponse>(`getGlobalSearch?search=${search}`);

        return data;
    }

    public async view(id: number, draft?: boolean): Promise<ApparatusModel> {
        const draftString = draft ? "?state=draft" : "";
        const { data } = await this._get<{ apparatus: ApparatusModel }>(`view/${id}${draftString}`);

        return data.apparatus;
    }

    public async viewLogs(id: number): Promise<boolean> {
        const { data } = await this._get<{ updated: boolean }>(`viewLogs/${id}`);

        return data.updated;
    }

    public async changeCostcenter(
        apparatusId: number | number[],
        costcenterId: number
    ): Promise<{ success: boolean; apparatus: ApparatusModel }> {
        const { data } = await this._post<{ success: boolean; apparatus: ApparatusModel }>(
            `changeCostcenter`,
            {
                apparatusId,
                costcenterId,
            }
        );

        return data;
    }

    public async addFiles(id: number, files: FileUrlType[]): Promise<boolean> {
        const formData = new FormData();

        if (files) {
            files.map((file, index) => {
                formData.append("uploads[file][]", file.file as File);
                if (file.fileType) {
                    formData.append(
                        `types[${index}]`,
                        file.fileType ? String(file.fileType.key) : ""
                    );
                }
            });
        }
        const { data } = await this._post<{ success: boolean }>(`addFiles/${id}`, formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        return data.success;
    }

    public async archive(apparatuses: number | number[]): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>(`archive`, {
            params: {
                apparatuses,
            },
        });

        return data.success;
    }

    public async checkIdentno(identno: string, apparatus_id?: number): Promise<boolean> {
        const { data } = await this._get<{ result: boolean }>(
            `checkIdentnoFreeForMandantor/${identno}${
                apparatus_id ? "/" + apparatus_id.toString() : ""
            }`
        );

        return data.result;
    }

    public async getCount(): Promise<number> {
        const { data } = await this._get<{ result: number }>("getCount");

        return data.result;
    }

    public async removeDraft(id: number): Promise<boolean> {
        const { data } = await this._delete<{ success: boolean }>(`removeDraft/${id}`);

        return data.success;
    }
}
