/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

export enum UserAttributeIdentifier {
    SHOW_TUTORIAL_LOCATION = "show_tutorial_location",
    SHOW_TUTORIAL_PLACE = "show_tutorial_place",
    REMIND_INTERVAL = "remind_interval",
    REMIND_WEEKDAY = "remind_weekday",
    COLOR_MODE = "color_mode",
    AUTO_ADD_TO_COSTCENTERS = "auto_add_to_costcenters",
}

export enum MandantorAttributeIdentifier {
    ESCALATER_INTERVAL = "escalater_interval",
    ESCALATER_USER_ID = "escalater_user_id",
    REMINDER_INTERVAL = "reminder_interval",
    REMINDER_FORECAST = "reminder_forecast",
}

export enum CompanyAttributeIdentifier {
    ESCALATER_INTERVAL = "escalater_interval",
    REMINDER_INTERVAL = "reminder_interval",
    REMINDER_FORECAST = "reminder_forecast",
}

export type UserAttribute = {
    [key in UserAttributeIdentifier]: string;
};

export type MandantorAttribute = {
    [key in MandantorAttributeIdentifier]: string;
};

export type CompanyAttribute = {
    [key in CompanyAttributeIdentifier]: string;
};

export type UserAttributeUpdate = {
    [key in UserAttributeIdentifier]?: string;
};

export type MandantorAttributeUpdate = {
    [key in MandantorAttributeIdentifier]?: string | null;
};

export type CompanyAttributeUpdate = {
    [key in CompanyAttributeIdentifier]?: string;
};

export default class AttributeAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("Attributes", "Attribute", "Attributes", adapter);
    }

    public async getAttributes<T>(
        model: "user" | "company" | "mandantor",
        foreignKey: number
    ): Promise<T> {
        const { data } = await this._get<{ result: T }>(`getAttributes/${model}/${foreignKey}`);

        return data.result;
    }

    public async updateUserAttribute(attributes: UserAttributeUpdate): Promise<unknown> {
        const { data } = await this._post<unknown>(`updateUserAttributes`, {
            attributes,
        });

        return data;
    }

    public async updateMandantorAttribute(attributes: MandantorAttributeUpdate): Promise<unknown> {
        const { data } = await this._post<unknown>(`updateMandantorAttributes`, {
            attributes,
        });

        return data;
    }

    public async updateCompanyAttribute({
        attributes,
        companyId,
    }: {
        attributes: CompanyAttributeUpdate;
        companyId: number;
    }): Promise<unknown> {
        const { data } = await this._post<unknown>(`updateCompanyAttributes`, {
            attributes,
            company_id: companyId,
        });

        return data;
    }
}
